@font-face {
  font-family: "LoRes";
  src: url("./fonts/LoRes9OTWide-Regular.woff") format("woff");
  font-display: swap;
}




body {
  margin: 0;
  font-family: "LoRes", "Pixelify Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070707;
  /* overflow-x: hidden; */

}

h1{
  font-family: "LoRes", "Pixelify Sans";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: black;
}

body::-webkit-scrollbar-thumb {
  background-color: #ff009c;
  border-radius: 20px;
  border: 2px solid black;
}

.scroll-container::-webkit-scrollbar {
  width: 10px;
}

.scroll-container::-webkit-scrollbar-track {
  background: black;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ff009c;
  border-radius: 20px;
  border: 2px solid black;
}
